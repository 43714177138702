<template>
  <div class="col-12 m-0 p-0 mb-3">
    <div class="row mx-0 p-2 ">
      <div class="col-12 col-xl-6 "></div>
      <div class="row col-12">
        <div class="col-12 col-xl-5 mt-2">
          <div class="input-group ml-3 mb-3 ">
            <div class="input-group-prepend">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
        <div class=" col-12 col-xl-6  mt-2 ">
          <cool-select
            :items="itemSelected"
            v-model="addSkudata.SkuCode"
            placeholder="--- เลือก SkuCode ---"
          />
        </div>
        <div class="col-12 col-xl-1 text-right mt-2">
          <button class="btn bt-bnb " @click="addSku()">
            เพิ่ม
          </button>
        </div>
        <div class="col-12 col-xl-6 mt-2">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(datas.totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="datas.items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Type)="row">
            <span class="badge badge-twd" v-if="row.item.Type == 'shortdesc'">
              ShortDesc</span
            >
            <span class="badge badge-main" v-else>LongDesc</span>
          </template>
          <template #cell(action)="row">
            <span
              v-html="$svg_icon.remove"
              v-if="$roleaction('Update Remarks')"
              type="button"
              class="mr-1 text-twd"
              @click="removeSku(row.item)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="datas.totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CoolSelect } from "vue-cool-select";
export default {
  components: { CoolSelect },
  name: "SkcodeExcept",
  props: ["keydata", "datas", "itemSelected"],
  data() {
    return {
      addSkudata: {
        SkuCode: "",
        Type: "",
        Val: "",
      },
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "SkCode",
      select_mode: [
        { name: "ค้นหา SkuCode", key: "SkCode" },
        { name: "ค้นหา UpdateBy", key: "UpdateBy" },
      ],
      fields: [
        {
          key: "SkCode",
          label: "SkCode",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        // { key: "Val", label: "cat Code", class: "text-left" },
        {
          key: "Type",
          label: "Type",
          class: "text-left",
        },
        { key: "CreateDate", label: "CreateDate", class: "text-left" },
        { key: "UpdateBy", label: "UpdateBy", class: "text-left" },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: { width: "5%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {},
  methods: {
    unSearch() {
      this.datas.items = this.datas.itemlist;
      this.datas.totalRows = this.datas.itemlist.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.datas.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.datas.items = results;
        this.datas.totalRows = this.datas.items.length;
      }
    },
    async addSku() {
      try {
        if (this.addSkudata.SkuCode == "") throw `กรุณาเลือก SkuCode`;

        const { Type, Val } = this.keydata;

        var data = {
          datas: {
            Actions: "add",
            Type: String(Type).trim(),
            CatCode: String(Val).trim(),
            SkuCode: String(this.addSkudata.SkuCode)
              .split(":")[0]
              .trim(),
          },
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/updatepimproductremarkmessageexcept`,
          data,
          1
        );
        this.$emit("getRemarkMessageExcept");
        if (getAPI.data == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        } else {
          throw getAPI.data;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async removeSku(row) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการลบ ${row.SkCode} / You need to delete ${row.SkCode}?`
        );
        if (confirm) {
          var data = {
            datas: {
              Actions: "delete",
              Type: String(row.Type).trim(),
              CatCode: "",
              SkuCode: String(row.SkCode).trim(),
            },
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `remark/updatepimproductremarkmessageexcept`,
            data,
            1
          );
          this.$emit("getRemarkMessageExcept");
          if (getAPI.data == "OK") {
            this.$serviceMain.showSuccessAlert(
              this,
              `ลบ SkuCode : ${String(row.SkCode).trim()} สำเร็จ / Complete`
            );
          } else {
            throw getAPI.data;
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
