<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-0 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600" v-if="actions == 'add'">Add Remark</p>
            <p class="font-1-2s font-600" v-else>Edit Remark</p>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <button class="btn bt-main mx-0 mb-3 mb-xl-0" @click="back()">
              <span v-html="$svg_icon.back"></span> Back
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row mx-0 my-4 pr-5 ">
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right">Show On Web</div>
              <div class="col-7">
                <select
                  class="form-control form-control-sm w-100"
                  v-model="data.ShowOnWeb"
                  :disabled="actions == 'edit'"
                  ><option value="">---กรุณาเลือก Web---</option>
                  <option value="PWB">PWB </option>
                  <!-- <option value="BNB">BNB </option>
                  <option value="BNB|TWD">BNB|TWD </option> -->
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right">Type Remark</div>
              <div class="col-7">
                <select
                  class="form-control form-control-sm w-100"
                  v-model="data.Type"
                  :disabled="actions == 'edit'"
                  ><option value="">---กรุณาเลือก Type---</option>
                  <option value="shortdesc">Short Description</option>
                  <option value="longdesc">Long Description </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right">Mode</div>
              <div class="col-7">
                <select
                  class="form-control form-control-sm w-100"
                  v-model="data.Mode"
                  :disabled="actions == 'edit'"
                  ><option value="">---กรุณาเลือก Mode---</option>
                  <option value="cat">Category </option>
                  <option value="sku">Sku </option>
                  <option value="all" disabled>All </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3" v-if="data.Mode == 'cat'">
              <div class="col-5 text-right">Cat Code</div>
              <div class="col-7">
                <div class="input-group input-group-sm ">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.Val"
                    :disabled="actions == 'edit'"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-inline mb-3" v-if="data.Mode == 'sku'">
              <div class="col-5 text-right">Sku Code</div>
              <div class="col-7">
                <input
                  :disabled="actions == 'edit'"
                  type="text"
                  class="form-control form-control-sm w-100"
                  v-model="data.Val"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right">วันที่เริ่มต้น</div>
              <div class="col-7">
                <input
                  type="date"
                  class="form-control form-control-sm w-100"
                  v-model="data.StartDate"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-5 text-right">วันที่สิ้นสุด</div>
              <div class="col-7">
                <div class="input-group input-group-sm  ">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="data.EndDate"
                  />
                  <div class="input-group-append  ">
                    <button class="btn bt-main" @click="set50y">50 YEAR</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="data.Type == 'longdesc'">
            <div class="row form-inline mb-3">
              <div class="col-2"></div>
              <div class="col-10 text-twd text-left font-weight-bold">
                *** หากเลือก Type Long Description
                ไม่ต้องระบุข้อความ "หมายเหตุ" ***
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-2 text-right mb-3">
              <span class="text-danger"></span>
            </div>
            <div class="col-12 col-lg-10 mb-3">
              <div class="row">
                <div class="col-6">
                  <p class="badge badge-primary">TH</p>
                  <tinymce
                    class="shadow bg-white rounded"
                    id="AddremarkModal"
                    :plugins="plugins"
                    :toolbar="toolbar"
                    :other_options="options"
                    v-model="data.MessageTH"
                  ></tinymce>
                </div>
                <div class="col-6">
                  <p class="badge badge-info ">EN</p>
                  <tinymce
                    class="shadow bg-white rounded"
                    id="AddremarkModalEN"
                    :plugins="plugins"
                    :toolbar="toolbar"
                    :other_options="options"
                    v-model="data.MessageEN"
                  ></tinymce>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 px-5 mb-3">
            <div
              class="form-check form-check-inline"
              v-for="(e, i) in active"
              :key="i"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="e.name"
                :value="e.key"
                v-model="data.Active"
              />
              <label class="form-check-label" :for="e.name">{{ e.name }}</label>
            </div>
          </div>
          <!-- --------------------- -->
          <div class="row w-100">
            <div class="col-12 text-right">
              <button class="btn bt-main px-5 mr-1" @click="confirm()">
                Save
              </button>
              <button class="btn btn-light px-5" @click="back()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container-fluid m-0 p-0"
      v-if="data.Mode == 'cat' && actions != 'add'"
    >
      <div class="row m-0 p-3">
        <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
          <div class="row m-0 p-3">
            <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
              <p class="font-1-2s font-600">
                SkCode Except
              </p>
            </div>
          </div>
          <hr class="m-0 p-0  mb-2" />
          <SkcodeExcept
            :keydata="data"
            :datas="SkcodeExceptData"
            :itemSelected="itemSelected"
            @getRemarkMessageExcept="getRemarkMessageExcept"
          />
        </div>
      </div>
    </div>
    <Modalfindbycatcode :key="reModalfindbycatcode" />
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import Modalfindbycatcode from "../../../components/common/Modalfindbycatcode.vue";
import SkcodeExcept from "./SkcodeExcept.vue";
export default {
  components: {
    SkcodeExcept,
    Modalfindbycatcode,
    tinymce,
  },
  name: "UpdateProductRemark",
  data() {
    return {
      itemSelected: [],
      SkcodeExceptData: {
        items: null,
        itemlist: null,
        totalRows: 0,
      },
      reModalfindbycatcode: Date.now(),
      catcode: this.$store.getters["categoryActions/get_category_remark"]
        .catcode,
      actions: this.$route.query.action,
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      // End Tinymce Editor
      title: "",
      textbt: "",
      data: {
        RemarkId: "",
        Mode: "",
        Val: "",
        Type: "",
        MessageTH: "",
        MessageEN: "",
        ShowOnWeb: "PWB",
        StartDate: "",
        EndDate: "",
        Active: 1,
      },
      active: [
        { key: 1, name: "Active" },
        { key: 0, name: "Inactive" },
      ],
      totalRows: 0,
      items: [],
    };
  },
  watch: {
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.lvl = v.lvl;
      this.data.Val = v.catcode;
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  async mounted() {
    await this.Clearcatcode();
    await this.getProductByRemark();
    await this.getRemarkMessageExcept();
    await this.getPimProductByCat();
  },
  methods: {
    set50y() {
      // data.EndDate;
      const date = new Date();
      date.setFullYear(date.getFullYear() + 50);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let to = `${year}-${String(month).length == 1 ? `0${month}` : month}-${
        String(day).length == 1 ? `0${day}` : day
      }`;

      this.data.EndDate = to;
    },
    async getProductByRemark() {
      let data = {
        remarkId: this.$route.query.id == undefined ? "" : this.$route.query.id,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `remark/pimproductremarkmessage`,
        data,
        1
      );
      const {
        RemarkId,
        Mode,
        Val,
        Type,
        MessageTH,
        MessageEN,
        ShowOnWeb,
        ShowDate,
        StartDate,
        EndDate,
        Active,
      } = getAPI.data[0];
      if (this.actions == "add") {
        this.data = {
          RemarkId: "",
          Mode: "",
          Val: "",
          Type: "",
          MessageTH: "",
          MessageEN: "",
          ShowOnWeb: "BNB|TWD",
          StartDate: "",
          EndDate: "",
          Active: 1,
        };
      } else {
        this.data = {
          RemarkId: RemarkId,
          Mode: Mode,
          Val: this.catcode == "" ? Val : this.catcode,
          Type: Type,
          MessageTH: MessageTH,
          MessageEN: MessageEN,
          ShowOnWeb: ShowOnWeb,
          StartDate: StartDate,
          EndDate: EndDate,
          Active: Active,
        };
      }
    },
    async getRemarkMessageExcept() {
      try {
        let data = {
          Mode: this.data.Mode,
          Type: this.data.Type,
          cat: this.data.Val,
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/pimproductremarkmessageexcept`,
          data,
          1
        );
        this.SkcodeExceptData.items = getAPI.data;
        this.SkcodeExceptData.itemlist = getAPI.data;
        this.SkcodeExceptData.totalRows = getAPI.data.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getPimProductByCat() {
      try {
        var data = { catCode: String(this.data.Val).trim() };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `product/getproductbycategory`,
          data,
          1
        );
        var selected = [];
        for (var key in getAPI.data) {
          selected.push(getAPI.data[key].ProductList);
        }
        this.itemSelected = selected;
      } catch (error) {
        console.log(error);
      }
    },
    async back() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "ManageRemarkMessage" });
      }
    },
    validate() {
      if (this.data.Mode == "") throw `โปรดกรอกข้อมูล Mode`;
      else if (this.data.ShowOnWeb == "") throw `โปรดกรอกข้อมูล ShowOnWeb`;
      else if (this.data.Val == "") throw `โปรดกรอกข้อมูล CatCode/SkuCode`;
      else if (this.data.Type == "") throw `โปรดกรอกข้อมูล Type Remark`;
      else if (this.data.StartDate == "") throw `โปรดกรอกข้อมูล วันที่เริ่มต้น`;
      else if (this.data.EndDate == "") throw `โปรดกรอกข้อมูล วันที่สิ้นสุด`;
      else if (this.data.StartDate > this.data.EndDate)
        throw `ไม่สามารถเลือกวันที่เริ่มต้นเกินวันที่สิ้นสุด`;
    },
    async confirm() {
      try {
        this.validate();
        let data = {
          id: this.$route.query.id,
          datas: this.data,
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/updatepimproductremarkmessage`,
          data,
          1
        );
        if (getAPI.data[0].MSG == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        } else {
          throw getAPI.data[0].MSG;
        }
        this.redata();
        this.$router.push({ name: "ManageRemarkMessage" });
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async close() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.hide();
      }
    },
    redata() {
      this.data = {
        explain: "",
        remarkHtmlTH: "",
        remarkHtmlEN: "",
        showAll: 0,
        active: 1,
      };
    },
    hide() {
      this.$bvModal.hide("Modalfindbycatcoderemark");
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
  },
};
</script>

<style></style>
